// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import LoginView from '@/views/LoginView.vue';
import HomeView from '@/views/HomeView.vue';
import DashboardView from '@/components/DashboardView.vue';
import UncollectedShipments from '@/components/UncollectedShipments.vue';
import ShipmentsToBePaid from '@/components/ShipmentsToBePaid.vue';
import CollectionMode from '@/components/CollectionMode.vue';
import RecentShipments from '@/components/RecentPackages.vue';
import ShipmentDetails from '@/components/ShipmentDetails.vue';
import SyncData from '@/components/SyncData.vue';

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    meta: { requiresAuth: false }
  },
  {
    path: '/',
    component: HomeView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'DashboardView',
        component: DashboardView
      },
      {
        path: 'uncollected',
        name: 'UncollectedShipments',
        component: UncollectedShipments
      },
      {
        path: 'tobepaid',
        name: 'ShipmentsToBePaid',
        component: ShipmentsToBePaid
      },
      {
        path: 'collection',
        name: 'CollectionMode',
        component: CollectionMode
      },
      {
        path: 'recent',
        name: 'RecentShipments',
        component: RecentShipments
      },
      {
        path: 'details',
        name: 'ShipmentDetails',
        component: ShipmentDetails
      },
      {
        path: 'SyncData',
        name: 'SyncData',
        component: SyncData
      }
    ]
  }
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      // Wait for the checkAuth action to complete
      await store.dispatch('checkAuth');
      
      // Re-check authentication status after checkAuth
      if (!store.getters.isAuthenticated) {
        // If not authenticated, redirect to login page
        return next({ name: 'LoginView', query: { redirect: to.fullPath } });
      }
    }
    next(); // Proceed if authenticated
  } else {
    if (isAuthenticated && to.name === 'LoginView') {
      // If already logged in and trying to access login page, redirect to dashboard
      return next({ name: 'DashboardView' });
    } else {
      next(); // Proceed if route does not require auth
    }
  }
});

export default router;
