<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <!-- Close Button -->
      <span class="close" @click="$emit('close')">&times;</span>
      <h2>Edit Package</h2>

      <!-- Two-column layout using grid -->
      <div class="form-grid">
        <!-- First Column -->
        <div class="form-group">
          <label for="trackingNo">Tracking No:</label>
          <input id="trackingNo" v-model="localPackage.trackingNo" type="text" />
        </div>

        <div class="form-group">
          <label for="manifestNumber">Manifest No:</label>
          <input id="manifestNumber" v-model="localPackage.manifestNumber" type="text" />
        </div>

        <div class="form-group">
          <label for="description">Description:</label>
          <input id="description" v-model="localPackage.description" type="text" />
        </div>

        <div class="form-group">
          <label for="originalWeight">Weight:</label>
          <input id="originalWeight" v-model="localPackage.originalWeight" type="float" />
        </div>

        <div class="form-group">
          <label for="status">Status:</label>
          <select id="status" v-model="localPackage.status">
            <option value="Pre Alert">Pre Alert</option>
            <option value="At Warehouse">At Warehouse</option>
            <option value="Collected">Collected</option>
            <option value="In Transit">In Transit</option>
            <option value="Ready">Ready</option>
            <option value="Delivered">Delivered</option>
            <option value="Out For Delivery">Out For Delivery</option>
            <option value="Entered">Entered</option>
            <option value="Attention">Attention</option>
          </select>
        </div>

           <!-- Collected Field (conditionally editable) -->
      <div class="form-group">
        <label for="collected">Collected:</label>
        <select id="collected" v-model="localPackage.collected">
          <option value="no">No</option>
          <option value="yes">Yes</option>
          <option value="partial">Partial</option>
        </select>
      </div>

        <!-- Customer Name and Lookup in the same line -->
        <div class="form-group-horizontal">
          <div class="form-subgroup">
            <label for="customerName">Customer Name:</label>
            <input id="customerName" v-model="customerName" type="text" disabled />
          </div>
          <button type="button" @click="openCustomerSearch" class="lookup-button">Lookup</button>
        </div>

        <div class="form-group">
          <label for="mailboxNumber">Mailbox Number:</label>
          <input id="mailboxNumber" :value="localPackage.Customer?.mailboxNumber || ''" type="text" readonly />
        </div>

        <!-- Second Column -->
        <div class="form-group">
          <label for="branch">Branch:</label>
          <!-- Add defensive v-if to ensure branches exist before rendering the select element -->
          <select id="branch" v-if="branches && branches.length" v-model="localPackage.branchId">
            <option v-for="branch in branches" :key="branch.id" :value="branch.id">{{ branch.name }}</option>
          </select>
          <p v-else>Loading branches...</p>
        </div>        

        <div class="form-group">
          <label for="merchant">Merchant:</label>
          <input id="merchant" v-model="localPackage.merchant" type="text" />
        </div>

        <div class="form-group">
          <label for="customsFee">Customs Fee:</label>
          <input id="customsFee" v-model="localPackage.customsFee" type="number" />
        </div>

        <div class="form-group">
          <label for="deliveryFee">Delivery Fee:</label>
          <input id="deliveryFee" v-model="localPackage.deliveryFee" type="number" />
        </div>

        <div class="form-group">
          <label for="createdAt">Created At:</label>
          <input id="createdAt" v-model="localPackage.createdAt" type="text" disabled />
        </div>

        <div class="form-group">
          <label for="updatedAt">Updated At:</label>
          <input id="updatedAt" v-model="localPackage.updatedAt" type="text" disabled />
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="form-actions">
        <button @click="save">Save</button>
        <button type="button" @click="$emit('close')" class="cancel-button">Cancel</button>
      </div>

      <!-- Customer Search Modal -->
      <div v-if="showCustomerSearch" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeCustomerSearch">&times;</span>
          <h2>Search Customer</h2>
          <input v-model="customerSearchQuery" @input="debounceSearchCustomer" type="text" placeholder="Search by name..." />
          <ul v-if="customerSearchResults && customerSearchResults.length">
            <li v-for="customer in customerSearchResults" :key="customer.id" @click="selectCustomer(customer)">
              {{ customer.firstName }} {{ customer.lastName }} ({{ customer.mailboxNumber }}) - {{ getBranchName(customer.branchId) }}
            </li>
          </ul>
          <p v-else>No customers found</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import debounce from 'lodash/debounce';
  export default {
    name: 'EditPackageModal',
    props: {
      showModal: Boolean,
      packageToEdit: Object,
    },
    data() {
      return {
        localPackage: { ...this.packageToEdit },
        customerName: this.packageToEdit.Customer
          ? `${this.packageToEdit.Customer.firstName} ${this.packageToEdit.Customer.lastName}`
          : '',
        showCustomerSearch: false,
        customerSearchQuery: '',
        customerSearchResults: [],
        branches: [],
      };
    },
    methods: {
      async fetchBranches() {
        this.branches = [
          { id: 7, name: 'Santa Cruz - Primary Location' },
          { id: 15, name: 'Portmore' },
          { id: 16, name: 'Old Harbour' },
          { id: 17, name: 'New Kingston' },
          { id: 26, name: 'Clarks Town' },
          { id: 27, name: 'Papine' },
          { id: 28, name: 'Maypen' },
        ];
      },
      getBranchName(branchId) {
        const branch = this.branches.find(branch => branch.id === branchId);
        return branch ? branch.name : 'N/A';
      },
      save() {
        // Emit the save event with the updated package data
        this.$emit('save', this.localPackage);
      },
      openCustomerSearch() {
        this.showCustomerSearch = true;
      },
      closeCustomerSearch() {
        this.showCustomerSearch = false;
      },
      debounceSearchCustomer: debounce(function () {
        this.searchCustomer(this.customerSearchQuery);
      }, 300),
      async searchCustomer(query) {
        const results = await this.$store.dispatch('searchCustomer', query);
        this.customerSearchResults = results || [];
      },
      selectCustomer(customer) {
        this.localPackage.Customer = customer;
        this.localPackage.branchId = customer.branchId;
        this.customerName = `${customer.firstName} ${customer.lastName} (${customer.mailboxNumber})`;
        this.showCustomerSearch = false;
      },
    },
    watch: {
      packageToEdit(newPackage) {
        this.localPackage = { ...newPackage };
        this.customerName = newPackage.Customer
          ? `${newPackage.Customer.firstName} ${newPackage.Customer.lastName}`
          : '';
      },
    },
    mounted() {
      this.fetchBranches();
    },
  };
</script>


<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.form-group-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.form-subgroup {
  flex: 1;
}

label {
  font-weight: bold;
  flex: 0 0 120px;
}

input,
select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button,
.cancel-button {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.save-button:hover {
  background-color: var(--hover-color);
}

.cancel-button {
  background-color: #ccc;
}

.lookup-button {
  background-color: var(--primary-color);
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  height: 44px;
}

.lookup-button:hover {
  background-color: var(--hover-color);
}
</style>
