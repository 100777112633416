<template>
  <div>
    <nav>
      <router-link to="/" exact active-class="router-link-exact-active">Dashboard</router-link>
      <router-link to="/uncollected" active-class="router-link-active">Uncollected Shipments</router-link>
      <router-link to="/tobepaid" active-class="router-link-active">Unpaid Shipments</router-link>
      <router-link to="/collection" active-class="router-link-active">Check-Off Packages</router-link>
      <router-link to="/recent" active-class="router-link-active">Require Attention</router-link>
      <router-link to="/details" active-class="router-link-active">All Shipment</router-link>
      <router-link to="/SyncData" active-class="router-link-active">Sync Data</router-link>
      <button class="logout-button" @click="performLogout">Logout</button>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'HomeView',
  methods: {
    ...mapActions(['logout']),
    performLogout() {
      this.logout();
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
/* Additional scoped styles if needed */
</style>
