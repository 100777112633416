<template>
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="$emit('close')">&times;</span>
        <h2>Package Details</h2>
        <p><strong>Tracking No:</strong> {{ packageData.trackingNo }}</p>
        <p><strong>Manifest No:</strong> {{ packageData.manifestNumber }}</p>
        <p><strong>Description:</strong> {{ packageData.description }}</p>
        <p><strong>Weight:</strong> {{ packageData.originalWeight }}</p>
        <p><strong>Status:</strong> {{ packageData.status }}</p>
        <p><strong>Collected:</strong> {{ packageData.collected }}</p>
        <p><strong>Branch:</strong> {{ packageData.branchId ? getBranchName(packageData.branchId) : 'N/A' }}</p>
        <p><strong>Customer Name:</strong> {{ packageData.Customer ? `${packageData.Customer.firstName} ${packageData.Customer.lastName}` : 'N/A' }}</p>
        <p><strong>Customs Fee:</strong> {{ packageData.customsFee }}</p>
        <p><strong>Delivery Fee:</strong> {{ packageData.deliveryFee }}</p>
        <p><strong>Merchant:</strong> {{ packageData.merchant }}</p>
        <p><strong>Created At:</strong> {{ formatDate(packageData.createdAt) }}</p>
        <p><strong>Updated At:</strong> {{ formatDate(packageData.updatedAt) }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ViewPackageModal',
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
      packageData: {
        type: Object,
        required: true,
      },
    },
    methods: {
      formatDate(dateString) {
        const options = { weekday: 'short', day: '2-digit', month: 'short', year: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-US', options);
      },
      getBranchName(branchId) {
        const branchMap = {
          7: "Santa Cruz - Primary Location",
          15: "Portmore",
          16: "Old Harbour",
          17: "New Kingston"
        };
        return branchMap[branchId] || "N/A";
      },
    }
  };
  </script>
  
  <style scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 600px;
    position: relative;
    max-height: 90%;
    overflow-y: auto;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
  }
  </style>
  