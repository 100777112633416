<template>
  <div class="login-view container">
    <form @submit.prevent="performLogin">
      <h1>Login</h1>
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" v-model="username" id="username" required />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <div class="password-wrapper">
          <input
            :type="passwordFieldType"
            v-model="password"
            id="password"
            required
          />
          <button type="button" @click="togglePasswordVisibility" class="toggle-password">
            {{ passwordFieldType === 'password' ? '👁️' : '🙈' }}
          </button>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" :disabled="isLoading">Login</button>
        <loading-spinner v-model:active="isLoading" :is-full-page="false"></loading-spinner>
      </div>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      passwordFieldType: 'password'
    };
  },
  computed: {
    ...mapGetters(['isLoading'])
  },
  methods: {
    ...mapActions(['login']),
    async performLogin() {
      try {
        this.errorMessage = ''; 
        await this.login({ username: this.username, password: this.password });
      } catch (error) {
        this.errorMessage = 'Login failed. Please check your credentials and try again.';
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    }
  }
};
</script>

<style scoped>
.login-view {
  max-width: 400px;
  margin: auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.password-wrapper {
  position: relative;
}

.password-wrapper input {
  padding-right: 40px;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.error-message {
  color: red;
  margin-top: 10px;
}
</style>
