<template>
  <div class="dashboard">
    <h1>Dashboard</h1>
    <div v-if="loading" class="loading-bar">Loading...</div>
    <div v-else>
      <div class="metrics">
        <div class="metric">
          <h2>Total Shipments</h2>
          <p>{{ totalShipments }}</p>
        </div>
        <div class="metric">
          <h2>Uncollected Shipments</h2>
          <p>{{ uncollectedShipments }}</p>
        </div>
        <div class="metric">
          <h2>Shipments to be Paid</h2>
          <p>{{ shipmentsToBePaid }}</p>
        </div>
      </div>
      <div class="chart">
        <h2>Shipments Over Time</h2>
        <div class="filter-options">
          <label>
            <input type="radio" v-model="timeFilter" value="day" /> Daily
          </label>
          <label>
            <input type="radio" v-model="timeFilter" value="week" /> Weekly
          </label>
          <label>
            <input type="radio" v-model="timeFilter" value="month" /> Monthly
          </label>
        </div>
        <canvas id="shipmentsChart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  name: 'DashboardView',
  data() {
    return {
      loading: true,
      timeFilter: 'day', // Default time filter to daily
      shipmentsChart: null
    };
  },
  computed: {
    ...mapState({
      shipments: state => state.shipments,
      totalShipments: state => state.shipments.length,
      uncollectedShipments: state => state.uncollectedShipments.length,
      shipmentsToBePaid: state => state.shipmentsToBePaid.length
    })
  },
  methods: {
    ...mapActions(['fetchAllShipments', 'fetchUncollectedShipments', 'fetchShipmentsToBePaid']),
    getFilteredData() {
      const currentDate = new Date();
      let timeUnit, dateDiffFunc, formatDateFunc;

      switch (this.timeFilter) {
        case 'day':
          timeUnit = 'day';
          dateDiffFunc = (date) => Math.floor((currentDate - new Date(date)) / (24 * 60 * 60 * 1000));
          formatDateFunc = (date) => new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' });
          break;
        case 'week':
          timeUnit = 'week';
          dateDiffFunc = (date) => Math.floor((currentDate - new Date(date)) / (7 * 24 * 60 * 60 * 1000));
          formatDateFunc = (date) => {
            const startDate = new Date(date);
            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + 6);
            return `${startDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })} - ${endDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}`;
          };
          break;
        case 'month':
        default:
          timeUnit = 'month';
          dateDiffFunc = (date) => (currentDate.getFullYear() - new Date(date).getFullYear()) * 12 + (currentDate.getMonth() - new Date(date).getMonth());
          formatDateFunc = (date) => new Date(date).toLocaleDateString('en-GB', { month: '2-digit', year: '2-digit' });
          break;
      }

      const dataMap = {};
      this.shipments.forEach(shipment => {
        const dateDiff = dateDiffFunc(shipment.createdAt);
        if (dateDiff >= 0 && dateDiff < 12) {
          const key = `${timeUnit}-${11 - dateDiff}`;
          if (!dataMap[key]) dataMap[key] = { totalPackages: 0, estimatedCost: 0 };
          dataMap[key].totalPackages += shipment.summary.totalPackages;
          dataMap[key].estimatedCost += shipment.summary.estimatedCost;
        }
      });

      const labels = Array.from({ length: 12 }, (_, i) => {
        const date = new Date(currentDate);
        switch (this.timeFilter) {
          case 'day':
            date.setDate(date.getDate() - i);
            break;
          case 'week':
            date.setDate(date.getDate() - (i * 7));
            break;
          case 'month':
          default:
            date.setMonth(date.getMonth() - i);
            break;
        }
        return formatDateFunc(date);
      }).reverse();

      const totalPackagesData = labels.map(label => (dataMap[label]?.totalPackages || 0));
      const estimatedCostData = labels.map(label => (dataMap[label]?.estimatedCost || 0));

      return { labels, totalPackagesData, estimatedCostData };
    },
    renderChart() {
      const canvas = document.getElementById('shipmentsChart');
      if (canvas) {
        if (this.shipmentsChart) this.shipmentsChart.destroy();

        const { labels, totalPackagesData, estimatedCostData } = this.getFilteredData();

        this.shipmentsChart = new Chart(canvas, {
          type: 'line',
          data: {
            labels,
            datasets: [
              {
                label: 'Total Packages',
                data: totalPackagesData,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
              },
              {
                label: 'Estimated Cost',
                data: estimatedCostData,
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1
              }
            ]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }
    }
  },
  async mounted() {
    console.log('Fetching Data...');
    this.loading = true;
    await this.fetchAllShipments();
    await this.fetchUncollectedShipments();
    await this.fetchShipmentsToBePaid();
    this.loading = false;
    console.log('Data Fetched:', this.shipments);
    this.renderChart();
  },
  watch: {
    shipments() {
      this.renderChart();
    },
    timeFilter() {
      this.renderChart();
    }
  }
};
</script>

<style scoped>
.dashboard {
  padding: 20px;
}

.metrics {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.metric {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 30%;
}

.loading-bar {
  text-align: center;
  font-size: 1.5em;
  color: #888;
}

.chart {
  width: 80%;
  margin: 0 auto;
}

.chart canvas {
  width: 100%;
  height: 400px;
}

.filter-options {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filter-options label {
  margin: 0 10px;
}
</style>
