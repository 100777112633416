<template>
  <ShipmentsTemplate
    title="Shipments to be Paid"
    :shipments="shipmentsToBePaid"
    :selectedShipment="selectedShipment"
    @edit-shipment="editShipment"
    @save-shipment="saveShipment"
    @close-modal="closeModal"
  />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ShipmentsTemplate from './common/ShipmentsTemplate.vue';

export default {
  name: 'UnpaidShipments',
  components: { ShipmentsTemplate },
  data() {
    return {
      selectedShipment: null,
    };
  },
  computed: {
    ...mapState(['shipmentsToBePaid']),
  },
  methods: {
    ...mapActions(['fetchShipmentsToBePaid', 'updateShipment']),
    editShipment(shipment) {
      this.selectedShipment = { ...shipment };
    },
    closeModal() {
      this.selectedShipment = null;
    },
    async saveShipment() {
      await this.updateShipment(this.selectedShipment);
      this.closeModal();
      this.fetchShipmentsToBePaid();
    },
  },
  mounted() {
    this.fetchShipmentsToBePaid();
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
