<template>
  <div class="recent-packages-page">
    <h1>Packages Requiring Attention</h1>

    <!-- Search Bar -->
    <div class="search-bar">
      <input v-model="searchQuery" type="text" placeholder="Search by Manifest or Tracking Number..." />
    </div>

    <!-- Package Table -->
    <PackageTable v-if="!loading" :packages="packages" @view-package="viewPackage" @edit-package="editPackage" :disable-collected-checkbox="true"/>
    <p v-else>Loading packages...</p>

    <!-- Page Size Selection -->
    <div class="page-size-selection">
      <label for="pageSize">Page Size:</label>
      <select v-model="pagination.pageSize" @change="fetchPackagesRequiringAttention">
        <option :value="10">10</option>
        <option :value="20">20</option>
        <option :value="50">50</option>
        <option :value="100">100</option>
      </select>
    </div>

    <!-- Pagination -->
    <div class="pagination">
      <button @click="pagination.page--" :disabled="pagination.page === 1">Previous</button>
      <span>Page {{ pagination.page }} of {{ totalPages }}</span>
      <button @click="pagination.page++" :disabled="pagination.page === totalPages">Next</button>
    </div>

    <!-- View Modal -->
    <ViewPackageModal 
      v-if="selectedPackage" 
      :showModal="!!selectedPackage" 
      :packageData="selectedPackage" 
      @close="closeModal" 
    />

    <!-- Edit Modal -->
    <EditPackageModal 
      v-if="editingPackage" 
      :showModal="!!editingPackage" 
      :packageToEdit="editingPackage"   
      @save="updatePackage"
      @close="closeEditModal" 
    />

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PackageTable from './common/PackageTable.vue';
import debounce from 'lodash/debounce';
import ViewPackageModal from './common/ViewPackageModal.vue'; // Import the reusable ViewPackageModal
import EditPackageModal from './common/EditPackageModal.vue'; // Import the reusable EditPackageModal

export default {
  name: 'RecentPackages',
  components: { PackageTable, ViewPackageModal, EditPackageModal },
  data() {
    return {
      selectedPackage: null,
      editingPackage: null,
      searchQuery: '',
      showCustomerSearch: false,
      customerSearchQuery: '',
      customerName: '',
      loading: false,
    };
  },
  computed: {
    ...mapState({
      recentShipments: state => state.recentShipments,
      pagination: state => state.pagination,
      customerSearchResults: state => state.customerSearchResults || [],
      packages: state => state.recentShipments,
    }),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.pageSize);
    },
    displayedPackages() {
      return this.recentShipments;
    },
  },
  methods: {
    ...mapActions(['fetchPackagesRequiringAttention', 'searchPackages', 'updatePackage', 'searchCustomer']),
    viewPackage(pkg) {
      this.selectedPackage = pkg;
    },
    editPackage(pkg) {
      this.editingPackage = { ...pkg };
      this.customerName = this.editingPackage.Customer 
        ? `${this.editingPackage.Customer.firstName} ${this.editingPackage.Customer.lastName} (${this.editingPackage.Customer.mailboxNumber})` 
        : '';
    },
    closeModal() {
      this.selectedPackage = null;
    },
    closeEditModal() {
      this.editingPackage = null;
      this.customerName = '';
    },
    prevPage() {
      if (this.pagination.page > 1) {
        this.pagination.page--;
        this.fetchPackagesRequiringAttention();
      }
    },
    nextPage() {
      if (this.pagination.page < this.totalPages) {
        this.pagination.page++;
        this.fetchPackagesRequiringAttention();
      }
    },
    searchPackage: debounce(function () {
      if (this.searchQuery) {
        this.searchPackages(this.searchQuery);
      } else {
        this.fetchPackagesRequiringAttention();
      }
    }, 300),
    async updatePackage(updatedPackage) {
      try {
        // Extract necessary fields from the editing package
        const packageUpdate = { 
          ...updatedPackage, 
          branchId: updatedPackage.branchId,
          ownerId: updatedPackage.Customer ? updatedPackage.Customer.id : null,
        };

        // Dispatch the Vuex action to update the package
        await this.$store.dispatch('updatePackage', packageUpdate);

        // Close the edit modal after a successful update
        this.closeEditModal();

        // Re-fetch the packages to reflect the latest changes
        this.fetchPackagesRequiringAttention();
      } catch (error) {
        console.error('Error updating package:', error);
      }
    },
    formatDate(dateString) {
      const options = { weekday: 'short', day: '2-digit', month: 'short', year: '2-digit' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    debounceSearchCustomer: debounce(function () {
      this.searchCustomer(this.customerSearchQuery);
    }, 300),
  },
  async mounted() {
    await this.fetchPackagesRequiringAttention();
  },
};
</script>


<style scoped>
.recent-packages-page {
  padding: 20px;
}

.page-size-selection {
  margin: 10px 0;
}

.page-size-selection select {
  padding: 5px;
  font-size: 16px;
  margin-left: 10px;
}

.search-bar input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.pagination button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
}

.pagination button:disabled {
  background-color: #ccc;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  max-height: 90%;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

label {
  margin-right: 10px;
  font-weight: bold;
  width: 150px;
}

input, select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex: 1;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.button:hover {
  background-color: var(--hover-color);
}

.cancel-button {
  background-color: #ccc;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

ul li:hover {
  background-color: #f0f0f0;
}
</style>
