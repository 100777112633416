import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { useToast } from "vue-toastification";

const toast = useToast();

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false, 
});

axiosInstance.interceptors.request.use(
  config => {
    const token = store.state.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized errors
      store.dispatch('logout'); // Log the user out
      router.push('/login');
      toast.error("Session expired. Please log in again.");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
