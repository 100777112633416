<template>
  <div class="collection-page">
    <h1 class="page-title">Package Checkoff</h1>

    <!-- Shipment Selection -->
    <div class="collapsible-section">
      <div class="section-header" @click="toggleSection('showShipmentSelection')">
        <span>Select Shipment IDs</span>
        <i :class="['toggle-icon', { open: showShipmentSelection }]">⮟</i>
      </div>
      <div v-if="showShipmentSelection" class="shipment-selector">
        <div class="shipment-checkboxes">
          <div v-for="shipment in uncollectedShipments" :key="shipment.id" class="checkbox-item">
            <input
              type="checkbox"
              :id="shipment.id"
              :value="shipment.id"
              v-model="selectedShipmentIds"
              @change="handleShipmentSelection(shipment.id)"
            />
            <label :for="shipment.id" class="shipment-label">
              <span class="shipment-id">{{ shipment.id }}</span>
              <span class="shipment-date">{{ formatDate(shipment.createdAt) }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Package Summary -->
    <div class="collapsible-section">
      <div class="section-header" @click="toggleSection('showPackageSummary')">
        <span>Package Summary</span>
        <i :class="['toggle-icon', showPackageSummary ? 'open' : '']">⮟</i>
      </div>
      <div v-show="showPackageSummary" class="summary">
        <table class="summary-table">
          <thead>
            <tr>
              <th>Branch</th>
              <th>Collected</th>
              <th>Not Collected</th>
              <th>Total Weight (lb)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, branch) in shipmentSummaryByBranch" :key="branch">
              <td>{{ branch }}</td>
              <td>{{ data.collected }}</td>
              <td>{{ data.notCollected }}</td>
              <td>{{ data.weight }}</td>
            </tr>
            <tr>
              <td><strong>Total</strong></td>
              <td>{{ collectedCount }}</td>
              <td>{{ notCollectedCount }}</td>
              <td>{{ totalWeight }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Package List Section -->
    <div class="collapsible-section">
      <div class="section-header" @click="toggleSection('showPackageList')">
        <span>Package List</span>
        <i :class="['toggle-icon', { open: showPackageList }]">⮟</i>
      </div>
      <div v-if="showPackageList" class="packages-container">
        <div class="search-filter-bar">
          <input v-model="searchQuery" type="text" placeholder="Search packages..." class="search-input" />
          <label class="filter-checkbox">
            <input type="checkbox" v-model="filterUncollected" /> Show Only Uncollected
          </label>
        </div>

        <!-- Package Table -->
        <PackageTable 
          :packages="packages" 
          @view-package="viewPackage" 
          @edit-package="editPackage"
          :disable-collected-checkbox="false"
        />

        <button @click="bulkUpdateStatus" class="primary-button">Update Status</button>
      </div>
    </div>

    <!-- View Modal -->
    <ViewPackageModal 
      v-if="selectedPackage" 
      :showModal="!!selectedPackage" 
      :packageData="selectedPackage" 
      @close="closeModal" 
    />

    <!-- Edit Package Modal -->
    <EditPackageModal
      v-if="editingPackage"
      :showModal="isModalVisible"
      :package-to-edit="editingPackage"
      @save="updatePackageInfo"
      @close="closeEditModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PackageTable from './common/PackageTable.vue'; // Reusable component
import EditPackageModal from './common/EditPackageModal.vue'; // Reusable component
import ViewPackageModal from './common/ViewPackageModal.vue'; // Import the reusable ViewPackageModal
import debounce from 'lodash/debounce';

export default {
  name: 'CheckoffPackages',
  components: { PackageTable, ViewPackageModal, EditPackageModal },
  data() {
    return {
      selectedShipmentIds: [],
      loadedShipmentIds: new Set(),
      searchQuery: '',
      packages: JSON.parse(sessionStorage.getItem('packages')) || [],
      loading: false,
      showShipmentSelection: true,
      showPackageSummary: true,
      showPackageList: true,
      selectedPackage: null,
      isModalVisible: false,
      editingPackage: null,
      filterUncollected: false,
      sortKey: '',
      sortOrder: 1,
    };
  },
  computed: {
    ...mapState(['uncollectedShipments']),
    sortedPackages() {
      return [...this.filteredPackages].sort((a, b) => {
        let aValue = this.getNestedValue(a, this.sortKey);
        let bValue = this.getNestedValue(b, this.sortKey);

        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) return -1 * this.sortOrder;
        if (aValue > bValue) return 1 * this.sortOrder;
        return 0;
      });
    },
    filteredPackages() {
      return this.packages.filter((pkg) => {
        const customerExists = pkg.Customer && pkg.Customer.firstName && pkg.Customer.lastName;
        const trackingNoMatch = pkg.trackingNo.toLowerCase().includes(this.searchQuery.toLowerCase());
        const manifestNoMatch = pkg.manifestNumber.toLowerCase().includes(this.searchQuery.toLowerCase());
        const customerFirstNameMatch = customerExists && pkg.Customer.firstName.toLowerCase().includes(this.searchQuery.toLowerCase());
        const customerLastNameMatch = customerExists && pkg.Customer.lastName.toLowerCase().includes(this.searchQuery.toLowerCase());

        let match = trackingNoMatch || manifestNoMatch || customerFirstNameMatch || customerLastNameMatch;

        if (this.filterUncollected) {
          match = match && pkg.collected !== 'yes';
        }

        return match;
      });
    },
    collectedCount() {
      return this.packages.filter((pkg) => pkg.collected === 'yes').length;
    },
    notCollectedCount() {
      return this.packages.filter((pkg) => pkg.collected !== 'yes').length;
    },
    totalWeight() {
      return this.packages.reduce((total, pkg) => total + parseFloat(pkg.originalWeight || 0), 0).toFixed(2);
    },
    shipmentSummaryByBranch() {
      const summary = {};

      this.packages.forEach((pkg) => {
        const branch = pkg.Branch ? pkg.Branch.name : 'N/A';
        if (!summary[branch]) {
          summary[branch] = {
            collected: 0,
            notCollected: 0,
            weight: 0,
          };
        }
        summary[branch].weight += parseFloat(pkg.originalWeight || 0);
        if (pkg.collected === 'yes') {
          summary[branch].collected += 1;
        } else {
          summary[branch].notCollected += 1;
        }
      });

      Object.keys(summary).forEach((branch) => {
        summary[branch].weight = summary[branch].weight.toFixed(2);
      });

      return summary;
    },
  },
  methods: {
    ...mapActions(['fetchUncollectedShipments', 'fetchPackagesByShipmentId', 'bulkUpdatePackages', 'searchCustomer']),
    handleShipmentSelection(shipmentId) {
      if (this.selectedShipmentIds.includes(shipmentId)) {
        this.loadPackages();
      } else {
        this.removePackagesByShipmentId(shipmentId);
      }
    },
    formatDate(dateString) {
      const options = { weekday: 'short', day: '2-digit', month: 'short', year: '2-digit' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    async loadPackages() {
      this.loading = true;
      for (const shipmentId of this.selectedShipmentIds) {
        if (!this.loadedShipmentIds.has(shipmentId)) {
          try {
            const response = await this.fetchPackagesByShipmentId(shipmentId);
            if (response && Array.isArray(response)) {
              const newPackages = response.filter((pkg) => !this.packages.some((existingPkg) => existingPkg.id === pkg.id));
              this.packages = this.packages.concat(newPackages);
              this.loadedShipmentIds.add(shipmentId);
            } else {
              console.error('Unexpected API response:', response);
            }
          } catch (error) {
            console.error('Error fetching packages:', error);
          }
        }
      }
      sessionStorage.setItem('packages', JSON.stringify(this.packages));
      this.loading = false;
    },
    async bulkUpdateStatus() {
      this.loading = true;
      try {
        // Prepare packages for update, only include those that are collected
        const updatedPackages = this.packages
          .filter(pkg => pkg.collected === 'yes' ||  pkg.collected === 'no' ) // Only update packages that are checked
          .map(pkg => ({
            id: pkg.id,
            status: pkg.status,
            collected: pkg.collected,
            originalWeight: pkg.originalWeight,
            chargedWeight: pkg.chargedWeight,
            shipmentId: pkg.shipmentId,
          }));

        // Map packages to their respective shipments
        const shipmentPackagesMap = updatedPackages.reduce((acc, pkg) => {
          if (!acc[pkg.shipmentId]) acc[pkg.shipmentId] = [];
          acc[pkg.shipmentId].push(pkg);
          return acc;
        }, {});

        // Update packages by shipment and fetch updated shipment details
        for (const shipmentId of Object.keys(shipmentPackagesMap)) {
          const shipmentPackages = shipmentPackagesMap[shipmentId];
          
          await this.bulkUpdatePackages({ updates: shipmentPackages });

          // Fetch updated shipment details
          const updatedShipmentPackages = await this.fetchPackagesByShipmentId(shipmentId);
          if (updatedShipmentPackages && Array.isArray(updatedShipmentPackages)) {
            // Update local package data with the refreshed details
            const newPackages = updatedShipmentPackages.filter(pkg => !this.packages.some(existingPkg => existingPkg.id === pkg.id));
            this.packages = this.packages.concat(newPackages);
          }
        }

        // Refresh uncollected shipments list
        await this.fetchUncollectedShipments();

        // Clear session storage and reset local data
        sessionStorage.removeItem('packages');
        this.packages = [];
        this.loadedShipmentIds.clear();
      } catch (error) {
        console.error('Error updating packages:', error);
      } finally {
        this.loading = false;
      }
    }
    ,
    removePackagesByShipmentId(shipmentId) {
      this.packages = this.packages.filter((pkg) => pkg.shipmentId !== shipmentId);
      this.loadedShipmentIds.delete(shipmentId);
      sessionStorage.setItem('packages', JSON.stringify(this.packages));
    },
    sortTable(key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1;
      } else {
        this.sortKey = key;
        this.sortOrder = 1;
      }
    },
    getNestedValue(obj, path) {
      return path.split('.').reduce((o, i) => (o ? o[i] : ''), obj);
    },
    toggleSection(section) {
      this[section] = !this[section];
    },
    editPackage(pkg) {
      this.editingPackage = pkg;
      this.isModalVisible = true;
    },
    updatePackageInfo(updatedPackage) {
      const index = this.packages.findIndex(pkg => pkg.id === updatedPackage.id);
      if (index !== -1) {
        this.packages.splice(index, 1, updatedPackage);
      }
      this.closeEditModal();
    },
    viewPackage(pkg) {
      this.selectedPackage = pkg;
    },
    closeModal() {
      this.selectedPackage = null;
    },
    closeEditModal() {
      this.isModalVisible = false;
      this.editingPackage = null;
    },
    openCustomerSearch() {
      this.showCustomerSearch = true;
    },
    closeCustomerSearch() {
      this.showCustomerSearch = false;
    },
    debounceSearchCustomer: debounce(async function () {
      await this.searchCustomer(this.customerSearchQuery);
    }, 300),
    selectCustomer(customer) {
      this.editingPackage.Customer = customer;
      this.editingPackage.branchId = customer.branchId;
      this.showCustomerSearch = false;
    },
    getBranchName(branchId) {
      const branchMap = {
        7: 'Santa Cruz - Primary Location',
        15: 'Portmore',
        16: 'Old Harbour',
        17: 'New Kingston',
      };
      return branchMap[branchId] || 'N/A';
    },
  },
  mounted() {
    this.fetchUncollectedShipments();
  },
  watch: {
    packages: {
      handler(newPackages) {
        sessionStorage.setItem('packages', JSON.stringify(newPackages));
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.collection-page {
  padding: 20px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  max-width: 2000px;
}

/* Collapsible Section */
.collapsible-section {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.section-header {
  padding: 10px;
  background-color: #e2e8f0;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-icon {
  transition: transform 0.3s ease;
}

.toggle-icon.open {
  transform: rotate(180deg);
}

.shipment-checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.checkbox-item input {
  margin-right: 10px;
}

.shipment-label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
}

.shipment-id {
  color: var(--primary-color); /* Highlight the Shipment ID with primary color */
  font-size: 16px;
  margin-bottom: 3px;
}

.shipment-date {
  color: #6c757d; /* Dim the color for the date */
  font-size: 12px;
}

/* Search and Filter Bar */
.search-filter-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.filter-checkbox {
  display: flex;
  align-items: center;
}

.packages-container {
  padding: 10px;
}

.packages-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

th,
td {
  border: 1px solid #ddd;
  padding: 10px;
}

th {
  /* background-color: #f8f9fa; */
  color: #343a40;
  text-align: left;
  cursor: pointer;
  position: relative;
}

th .sort-indicator {
  margin-left: 5px;
  font-size: 0.8em;
  color: #6c757d;
}

td {
  background-color: white;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 16px;
  padding: 5px;
  transition: color 0.3s ease;
}

.icon-button:hover {
  color: #0056b3;
}

.summary {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  font-weight: bold;
  padding: 10px;
  background-color: #e2e8f0;
  border-radius: 5px;
}

.summary-item {
  text-align: center;
}

.summary-table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.summary-table th,
.summary-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.summary-table th {
  background-color: #e2e8f0;
}

.loading-spinner {
  display: block;
  margin: 0 auto;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #2563eb;
  width: 40px;
  height: 40px;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 600px;
  position: relative;
  max-height: 90%;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

label {
  margin-right: 10px;
  font-weight: bold;
  width: 150px;
}

input,
select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex: 1;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.form-actions button {
  margin-left: 0;
  flex: 1;
  margin-right: 10px;
}

.edit-button {
  background-color: #ffc107;
}

.edit-button:hover {
  background-color: #ffca2c;
}
</style>
