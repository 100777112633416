import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import LoadingSpinner from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css'; // Updated path for CSS file
import '@/assets/styles/global.css'; // Import global stylesheet

const options = {
  // Set your default options here
  position: POSITION.TOP_RIGHT,
  timeout: 3000, // 3 seconds
};

const app = createApp(App);

app.use(router);
app.use(store);
app.use(Toast, options);
app.component('LoadingSpinner', LoadingSpinner); // Register the loading component globally

app.mount('#app');
