<template>
  <table class="packages-table">
    <thead>
      <tr>
        <th @click="sortTable('trackingNo')">Tracking No <span class="sort-indicator">⬍</span></th>
        <th @click="sortTable('manifestNumber')">Manifest No <span class="sort-indicator">⬍</span></th>
        <th @click="sortTable('status')">Status <span class="sort-indicator">⬍</span></th>
        <th @click="sortTable('originalWeight')">Weight <span class="sort-indicator">⬍</span></th>
        <th @click="sortTable('collected')">Collected <span class="sort-indicator">⬍</span></th>
        <th @click="sortTable('Branch.name')">Branch <span class="sort-indicator">⬍</span></th>
        <th @click="sortTable('Customer.firstName')">Customer Name <span class="sort-indicator">⬍</span></th>
        <th @click="sortTable('description')">Description <span class="sort-indicator">⬍</span></th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="pkg in sortedPackages" :key="pkg.id">
        <td>{{ pkg.trackingNo }}</td>
        <td>{{ pkg.manifestNumber }}</td>
        <td>{{ pkg.status }}</td>
        <td>{{ pkg.originalWeight }}</td>
        <!-- Conditionally disable the checkbox based on the disableCollectedCheckbox prop -->
        <td><input type="checkbox" :checked="pkg.collected === 'yes'" @change="toggleCollected(pkg)" :disabled="disableCollectedCheckbox" /></td>
        <td>{{ pkg.Branch ? pkg.Branch.name : 'N/A' }}</td>
        <td>{{ pkg.Customer ? `${pkg.Customer.firstName} ${pkg.Customer.lastName}` : 'N/A' }}</td>
        <td>{{ pkg.description }}</td>
        <td class="action-buttons">
          <button class="icon-button view-button" @click="$emit('view-package', pkg)">
            <i class="fas fa-pencil-alt"></i> View
          </button>
          <button class="icon-button edit-button" @click="$emit('edit-package', pkg)">
            <i class="fas fa-pencil-alt"></i> Edit
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'PackageTable',
  props: {
    packages: {
      type: Array,
      default: () => [], // Provide default value to ensure it's an array
    },
    disableCollectedCheckbox: {
      type: Boolean,
      default: false, // By default, the checkbox is editable
    },
  },
  data() {
    return {
      sortKey: '',
      sortOrder: 1,
    };
  },
  computed: {
    sortedPackages() {
      return [...this.packages].sort((a, b) => {
        let aValue = this.getNestedValue(a, this.sortKey);
        let bValue = this.getNestedValue(b, this.sortKey);

        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) return -1 * this.sortOrder;
        if (aValue > bValue) return 1 * this.sortOrder;
        return 0;
      });
    },
  },
  methods: {
    toggleCollected(pkg) {
      pkg.collected = pkg.collected === 'yes' ? 'no' : 'yes';
      this.$emit('update-package', pkg);
    },
    sortTable(key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1;
      } else {
        this.sortKey = key;
        this.sortOrder = 1;
      }
    },
    getNestedValue(obj, path) {
      return path.split('.').reduce((o, i) => (o ? o[i] : ''), obj);
    },
  },
};
</script>
  
  <style scoped>
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: var(--primary-color);
    color: white;
    text-align: left;
  }
  
  td {
    background-color: white;
  }
  
  button {
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin: 5px 0;
  }
  
  .view-button {
    background-color: var(--primary-color);
  }
  
  .view-button:hover {
    background-color: #3498db;
  }
  
  .edit-button {
    background-color: #ffc107;
  }
  
  .edit-button:hover {
    background-color: #ffca2c;
  }
  </style>
  