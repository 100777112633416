<template>
    <div class="shipments-template">
      <h1>{{ title }}</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Total Packages</th>
            <th>Collected</th>
            <th>Outstanding</th>
            <th>Original Weight</th>
            <th>Estimated Cost</th>
            <th>Paid</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="shipment in shipments" :key="shipment.id">
            <td>{{ shipment.id }}</td>
            <td>{{ shipment.summary.totalPackages }}</td>
            <td>{{ shipment.summary.totalCollected }}</td>
            <td>{{ shipment.summary.uncollectedAmount }}</td>    
            <td>{{ shipment.summary.totalOriginalWeight }}</td>
            <td>{{ formatAmount(shipment.summary.estimatedCost) }}</td>
            <td>{{ formatAmount(shipment.amountPaid) }}</td>
            <td>{{ formatDate(shipment.createdAt) }}</td>
            <td><button @click="editShipment(shipment)">Edit</button></td>
          </tr>
        </tbody>
      </table>
  
      <div v-if="localSelectedShipment" class="modal">
        <div class="modal-content">
          <h2>Edit Shipment</h2>
          <form @submit.prevent="saveShipment">
            <div class="form-group">
              <label for="id">ID:</label>
              <input id="id" v-model="localSelectedShipment.id" type="text" disabled />
            </div>
            <div class="form-group">
              <label for="createdBy">Created By:</label>
              <input id="createdBy" v-model="localSelectedShipment.createdBy" type="text" disabled />
            </div>
            <div class="form-group">
              <label for="updatedBy">Updated By:</label>
              <input id="updatedBy" v-model="localSelectedShipment.updatedBy" type="text" disabled />
            </div>
            <div class="form-group">
              <label for="estimatedTotalCost">Estimated Total Cost:</label>
              <input id="estimatedTotalCost" :value="formatAmount(localSelectedShipment.summary.estimatedCost)" type="text" disabled />
            </div>
            <div class="form-group">
              <label for="amountPaid">Amount Paid:</label>
              <input id="amountPaid" v-model="localSelectedShipment.amountPaid" type="number" />
            </div>
            <div class="form-group">
              <label for="createdAt">Created At:</label>
              <input id="createdAt" :value="formatDate(localSelectedShipment.createdAt)" type="text" disabled />
            </div>
            <div class="form-group">
              <label for="updatedAt">Updated At:</label>
              <input id="updatedAt" :value="formatDate(localSelectedShipment.updatedAt)" type="text" disabled />
            </div>
            <div class="form-actions">
              <button type="submit">Save</button>
              <button type="button" @click="closeModal">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ShipmentsTemplate',
    props: {
      title: {
        type: String,
        required: true,
      },
      shipments: {
        type: Array,
        required: true,
      },
      selectedShipment: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        localSelectedShipment: this.selectedShipment ? { ...this.selectedShipment } : null,
      };
    },
    watch: {
      selectedShipment(newVal) {
        this.localSelectedShipment = newVal ? { ...newVal } : null;
      },
    },
    methods: {
      editShipment(shipment) {
        this.$emit('edit-shipment', shipment);
      },
      saveShipment() {
        this.$emit('save-shipment', this.localSelectedShipment);
      },
      closeModal() {
        this.$emit('close-modal');
      },
      formatAmount(amount) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(amount);
      },
      formatDate(date) {
        const options = {
          day: '2-digit',
          month: 'short',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        };
        return new Date(date).toLocaleString('en-US', options).replace(/,/, '');
      },
    },
  };
  </script>
  
  <style scoped>
  .shipments-template {
    padding: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: var(--primary-color);
    color: white;
    text-align: left;
  }
  
  td {
    background-color: white;
  }
  
  button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
  }
  
  button:hover {
    background-color: var(--hover-color);
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 600px;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  label {
    margin-right: 10px;
    font-weight: bold;
    width: 150px;
  }
  
  input, select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex: 1;
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .form-actions button {
    margin-left: 0;
    flex: 1;
    margin-right: 10px;
  }
  </style>
  